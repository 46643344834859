import React, { Fragment, useEffect, useState } from "react";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  CardHeader,
  CircularProgress,
  Divider,
  ListSubheader,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { primaryColor } from "../../../../assets/theme/palette";
import { getPassesByStatus } from "../../API/apiSettings";
import { ApprovalRequestStatus } from "../../Models/notifications";
import { PassRequiresApprovalModel } from "../../Models/settings";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1110,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.9em",
        width: "3em",
        height: "1em",
        "&::before": {
          borderWidth: "0 1em 1em 1em",
          borderColor: `transparent transparent ${theme.palette.grey[300]} transparent`,
        },
      },

      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.9em",
        width: "3em",
        height: "1em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `${theme.palette.grey[300]} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.9em",
        height: "3em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 1em 1em 0",
          borderColor: `transparent ${theme.palette.grey[300]} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.9em",
        height: "3em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 0 1em 1em",
          borderColor: `transparent transparent transparent ${theme.palette.grey[300]}`,
        },
      },
    },
    arrow: {
      zIndex: 998,
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
    cardContentRoot: {
      padding: "8px",
      paddingTop: "0px",
      "&:last-child": {
        paddingBottom: "0",
      },
    },
    emptyContainer: {
      minWidth: "5em",
      minHeight: "7em",
      justifyContent: "center",
      alignItems: "center",
    },
    emptyText: {
      color: theme.palette.grey[600],
    },
  })
);
interface Item {
  id: string;
  icon?: any;
  primaryText: string;
  secondaryText?: string;
  selected?: boolean;
  disabled?: boolean;
  tooltipDisabledMessage?: string;
}
interface Props {
  open: boolean;
  reference: any;
  onClose: (event: React.MouseEvent<Document, MouseEvent>) => void;
  onClickItem: (itemId: string) => void;
  placement?: PopperPlacementType;
}
const MenuNotification = ({
  open,
  reference,
  onClose,
  onClickItem,
  placement,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [arrowRef, setArrowRef] = React.useState(null);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [passList, setPassList] = useState<PassRequiresApprovalModel[]>([]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const load = async () => {
      try {
        setIsLoading(true);
        const response = await getPassesByStatus(ApprovalRequestStatus.Pending, signal);
        if (!response.data.hasErrors) {
          const temp = (response.data.payload as PassRequiresApprovalModel[]).map(
            (notification) => ({
              id: notification.id,
              primaryText: t("Request") + " " + t("From"),
              secondaryText: notification.fullName,
              selected: false,
              icon: <LabelImportantIcon fontSize="small" />,
            })
          ) as any;
          setPassList(temp);
        }
      } catch (error) {
        //
      } finally {
        setIsLoading(false);
      }
    };
    if (open) {
      load();
    }
  }, [open]);
  return (
    <Fragment>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={reference}
        placement={placement ?? "bottom"}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <div style={{ zIndex: 999, paddingTop: theme.spacing(1) }}>
              <div className={classes.arrow} ref={setArrowRef as any} />
              <ClickAwayListener
                onClickAway={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onClose(event);
                }}
                // touchEvent={false}
              >
                <Card
                  elevation={9}
                  // variant="outlined"
                  style={{ minWidth: "150px", display: "flex", justifyContent: "center" }}
                >
                  <List
                    aria-label="notification items"
                    subheader={
                      <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        style={{ backgroundColor: "whitesmoke", maxWidth: "100%" }}
                      >
                        {t("PendingApprovalRequest")}
                      </ListSubheader>
                    }
                    disablePadding
                    color="primary"
                  >
                    {isLoading ? (
                      <Grid container justify="center">
                        <CircularProgress size={20} />
                      </Grid>
                    ) : (
                      passList.map((item: Item, index: number) => {
                        return (
                          <ListItem
                            key={item.id + "-" + index}
                            selected={item.selected}
                            dense
                            button
                            onClick={() => {
                              if (!item.disabled) {
                                onClickItem(item.id);
                              }
                            }}
                            style={{
                              opacity: item.disabled ? 0.5 : 1,
                              cursor: item.disabled ? "auto" : "pointer",
                            }}
                          >
                            {item.icon ? (
                              <ListItemIcon style={{ marginRight: "-20px" }}>
                                {item.icon}
                              </ListItemIcon>
                            ) : null}
                            <Tooltip
                              title={item.disabled ? item.tooltipDisabledMessage ?? "" : ""}
                              arrow
                            >
                              <ListItemText
                                primary={item.primaryText}
                                secondary={
                                  <Typography variant="caption">{item.secondaryText}</Typography>
                                }
                              />
                            </Tooltip>
                          </ListItem>
                        );
                      })
                    )}
                  </List>
                </Card>
              </ClickAwayListener>
            </div>
          );
        }}
      </Popper>
    </Fragment>
  );
};
export default MenuNotification;

import React, { Fragment, useEffect, Suspense, useState, useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { lightTheme, blackTheme } from "../assets/theme/generalTheme";
import Layout from "./Layout/Layout";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { generalPortalRoutes, pathRoute } from "./Utils/Routes/routes";
/////////////////////////////////////////////////////
import CreateNavLinks from "./Layout/SideBarNav/CreateNavLinks";
import GeneralLoading from "./Utils/Custom/CustomLoading/GeneralLoading";
import PrivateRoute from "./Auth/PrivateRoute";
import { connect } from "react-redux";
import Page404 from "./Utils/Custom/Page404";
import SignIn from "./Pages/SignInUp/SignIn";
import SignUp from "./Pages/SignInUp/SignUp";
import ForgotPassword from "./Pages/SignInUp/ForgotPassword";
import RedirectEmailActions from "./Pages/SignInUp/RedirectEmailActions";
import { Slide, toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import StoreStateModel from "./Utils/Models/store";
import ErrorBoundary from "./Utils/Custom/ErrorBoundary";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Page500 from "./Utils/Custom/Page500";
/////
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { SWRConfig } from "swr";
import NotificationGeneralApp from "./Utils/Custom/NotificationsGeneralApp";
import { hideElementBecauseLocationIsPublic } from "./Utils/Custom/HelperCode";
import Audios from "../components/Utils/Constants/audios";

console.log("env", process.env.NODE_ENV);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  environment: process.env.NODE_ENV || "unknown",
  // autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // this avoid to capture errors during development in localhost
  // beforeSend: (event) => {
  //   if (window.location.hostname === 'localhost') {
  //     return null;//null
  //   }
  //   return event;
  // },
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      // Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },

  //this fix the issues with the payload too large ==> https://github.com/getsentry/sentry-javascript/issues/2798
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === "console") {
      return null;
    }
    return breadcrumb;
  },
});
interface Props {
  showLoadingComponent?: boolean;
  isAuthenticated?: boolean;
  isVerifying?: boolean;
  scopes?: string[];
  interceptorErrorMessage?: string;
  interceptorErrorId?: number;
  prefersDarkMode?: boolean;
  portal?: string;

  userId?: string;
  accountId?: number;
  email?: string;
  userName?: string;
  token?: string;
  accountFullName?: string;

  deviceType?: string;
  totalDevicesConnectedWebRTC?: string[];
  hasPermissionAccountRead?: boolean;
  didCallAPILockdownInfo: boolean;
  innerWidth?: number;
  innerHeight?: number;
}
function PagesRouteLogic({
  showLoadingComponent,
  isAuthenticated,
  isVerifying,
  scopes,
  interceptorErrorMessage,
  interceptorErrorId,
  prefersDarkMode,
  portal,

  userId,
  accountId,
  email,
  userName,
  token,
  accountFullName,
  deviceType,
  totalDevicesConnectedWebRTC,
  hasPermissionAccountRead,
  didCallAPILockdownInfo,
  innerWidth,
  innerHeight,
}: Props) {
  const { t } = useTranslation();
  const location: any = useLocation();
  const [initialAudioWithUserEvent, setInitialAudioWithUserEvent] = useState<
    HTMLAudioElement | undefined
  >(undefined);

  Sentry.configureScope(function (scope) {
    scope.setUser({
      email: email,
      name: userName,
    });
  });
  Sentry.setContext("Permissions", {
    scopes: JSON.stringify(scopes),
  });

  useEffect(() => {
    if (interceptorErrorMessage) {
      toast.error(t(interceptorErrorMessage), { toastId: interceptorErrorId });
    }
    return () => {
      toast.dismiss(interceptorErrorId);
    };
  }, [interceptorErrorId]);

  const theme = React.useMemo(
    () => createMuiTheme(prefersDarkMode ? blackTheme : (lightTheme as any)),
    [prefersDarkMode]
  );

  useEffect(() => {
    const audio = new Audio();
    audio.src = Audios.NOTIFICATION_DOORBELL;
    setInitialAudioWithUserEvent(audio);
    audio.volume = 0; //Set volume to 0 to mute
    audio.muted = true;
    audio.autoplay = true;
  }, []);

  return (
    <div>
      {/* It allows you to customize all design aspects of your project in order to meet the specific needs of your business or brand. */}
      <ThemeProvider theme={theme}>
        {/* fixes some inconsistencies across browsers and devices while providing slightly more opinionated resets to common HTML elements */}
        <CssBaseline />

        {/* ----- */}
        <Sentry.ErrorBoundary
          showDialog={false}
          fallback={({ error, componentStack, resetError }) => {
            return (
              <React.Fragment>
                <Grid container justify="center" style={{ marginTop: "20px" }}>
                  <Page500 />
                  <Button
                    style={{ marginTop: "20px" }}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() =>
                      //  When resetError() is called it will remove the Fallback component
                      //  and render the Sentry ErrorBoundary's children in their initial state
                      resetError()
                    }
                  >
                    {t("Back")}
                  </Button>
                </Grid>
              </React.Fragment>
            );
          }}
        >
          {scopes?.length ? (
            hideElementBecauseLocationIsPublic(location.pathname) ? null : (
              <NotificationGeneralApp
                accountId={accountId as number}
                totalDevicesConnectedWebRTC={totalDevicesConnectedWebRTC as string[]}
                hasPermissionAccountRead={hasPermissionAccountRead as boolean}
                didCallAPILockdownInfo={didCallAPILockdownInfo}
                userId={userId as string}
                audioNotification={initialAudioWithUserEvent}
              />
            )
          ) : null}
          <SWRConfig
            //https://swr.vercel.app/docs/options
            value={{
              suspense: false, //https://swr.vercel.app/docs/suspense
              revalidateOnFocus: true,
              errorRetryCount: 0,
              shouldRetryOnError: false,
            }}
          >
            <Switch>
              {generalPortalRoutes[portal as string].map((route) => {
                return (
                  <PrivateRoute
                    key={route.id}
                    path={route.path}
                    exact={route.exact}
                    autorization={route.authorization}
                    isAuthenticated={isAuthenticated as any}
                    isVerifying={isVerifying as any}
                    scopes={scopes as any}
                  >
                    {showLoadingComponent ? (
                      <GeneralLoading />
                    ) : (
                      <Layout
                        sideBarNavList={
                          <CreateNavLinks
                            accountFullName={accountFullName}
                            userName={userName}
                            scopes={scopes as any}
                            portal={portal as string}
                          />
                        }
                      >
                        <ErrorBoundary>
                          <Suspense fallback={<GeneralLoading />}>
                            {route.mainContentComponent}
                          </Suspense>
                        </ErrorBoundary>
                      </Layout>
                    )}
                  </PrivateRoute>
                );
              })}
              <Route exact path={pathRoute.SignIn.Index}>
                <SignIn />
              </Route>
              <Route exact path={pathRoute.SignUp.Index}>
                <SignUp />
              </Route>
              <Route exact path={pathRoute.SignIn.ForgotPassword}>
                <ForgotPassword />
              </Route>
              <Route exact path={pathRoute.Action}>
                <RedirectEmailActions
                  deviceType={deviceType as string}
                  innerWidth={innerWidth as number}
                  innerHeight={innerHeight as number}
                />
              </Route>
              <Route path="*">
                <Page404 />
              </Route>
            </Switch>
          </SWRConfig>
        </Sentry.ErrorBoundary>
        <ToastContainer
          position="bottom-right"
          autoClose={7000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          transition={Slide}
          limit={5}
          // toastClassName
          // bodyClassName
        />
      </ThemeProvider>
    </div>
  );
}
const mapStateToProps = (state: StoreStateModel /*, ownProps*/) => {
  return {
    //NOTE: dont use useSelector hooks here
    showLoadingComponent: state.general.showLoadingComponent,
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    scopes: state.auth.scopes,
    interceptorErrorMessage: state.general.interceptorErrorMessage,
    interceptorErrorId: state.general.interceptorErrorId,
    prefersDarkMode: state.layout.prefersDarkMode,
    portal: state.general.portalId,

    userId: state.auth.uid,
    accountId: state.auth.accountId,
    email: state.auth.email,
    userName: state.auth.userName,
    token: state.auth.token,
    accountFullName: state.auth.accountName,

    deviceType: state.auth.deviceType,

    totalDevicesConnectedWebRTC: state.general.totalDevicesConnectedWebRTC,

    hasPermissionAccountRead: state.auth.hasPermissionAccountRead,
    didCallAPILockdownInfo: state.general.didCallAPILockdownInfo,
    innerWidth: state.layout.innerWidth,
    innerHeight: state.layout.innerHeight,
  };
};

export default connect(mapStateToProps, null)(PagesRouteLogic);
